import React, { useState, useEffect } from "react";
import AnimatedScreen from "../AnimatedScreen/AnimatedScreen";
import GameSetUp from "../GameSetUp/GameSetUp";
import QuestionBoard from "../QuestionBoard";
import ScoreBoard from "../ScoreBoard";
import socketIOClient from "socket.io-client";
import { Helmet } from "react-helmet";
import useLocalStorage from "../../hooks/useLocalStorage";
import clockSound from "../../sounds/clock.wav";
import { FeedbackButton, EditButton, Main } from "./styled";
import _ from "lodash";

const ENDPOINT =
  process.env.NODE_ENV === "development" ? "http://127.0.0.1:4001" : "";

const setDailyDoubles = (gridSize) => {
  const dailyDouble1 = Math.floor(Math.random() * gridSize);
  let dailyDouble2 = Math.floor(Math.random() * gridSize);

  while (dailyDouble1 === dailyDouble2) {
    dailyDouble2 = Math.floor(Math.random() * gridSize);
  }

  return [dailyDouble1, dailyDouble2];
};

const MainScreenContainer = (props) => {
  const initialState = {
    id: props.match.params.id,
    host: props?.location?.state?.host,
    completed: false,
    theme: {},
    grid: [5, 7],
    players: [],
    categories: [],
    dailyDouble: setDailyDoubles(30),
  };

  const gameId = props.match.params.id;
  const [game, setGame] = useLocalStorage(gameId, initialState);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [themeId, setThemeId] = useState(game.themeId || 0);
  const [played, setPlayed] = useState([]);
  const [players, setPlayers] = useState([]);
  const [questions, setQuestions] = useState(game.questions || []);
  const [buzzed, setBuzzed] = useState();
  const [buzzer, setBuzzer] = useState();
  const [minTime, setMinTime] = useState(Number.POSITIVE_INFINITY);
  const [stats, setStats] = useState({ fastestBuzzer: [] });
  const socket = socketIOClient(ENDPOINT);
  const host = props?.location?.state?.host;

  useEffect(() => {
    socket.emit("room", gameId);

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    socket.on("buzzer", (data) => {
      setBuzzer(data);
    });
    socket.on("reset", (data) => {
      setBuzzed("");
      setMinTime(Number.POSITIVE_INFINITY);
    });

    socket.on("addPlayer", (data) => {
      players.push({ name: data.name, score: 0 });
      setPlayers(players.concat([{ name: data.name, score: 0 }]));
    });

    return () => {
      socket.off("buzzer");
      socket.off("reset");
      socket.off("addPlayer");
    };
  }, []);

  useEffect(() => {
    if (buzzer === undefined) return;

    if (buzzer.time < minTime) {
      setBuzzed(buzzer.user);
      setMinTime(buzzer.time);

      stats.fastestBuzzer.push(buzzer.user);
      calculateStats(stats.fastestBuzzer)
    }
  }, [buzzer]);

  useEffect(() => {
    if (game.id) {
      props.setThemeId(themeId);
      setGame({ ...game, themeId });
    }
  }, [themeId]);

  useEffect(() => {
    if (game.id) setGame({ ...game, players: players.slice(0, -1) });
  }, [players]);

  useEffect(() => {
    if (game.id && questions.length > 0) {
      setGame({
        ...game,
        categories: questions,
        completed: questions.length > 0,
      });
    }
  }, [questions]);

  const updatePlayed = (id) => {
    setPlayed([...played, id]);
  };

  const changeScore = (id, value) => {
    game.players[id].score = game.players[id].score + value;
    setGame({ ...game });
  };

  /**
    TODO: calculate the fastest buzzer at the end
  **/
  const calculateStats = (data) => {
    console.log(_.countBy(data))
  }

  let allQuestions = [];
  if (game.categories === undefined) {
    allQuestions = [];
  } else {
    game.categories.map(({ questions }) => {
      return questions.map((item) => {
        return allQuestions.push(item);
      });
    });
  }

  return (
    <>
      <Helmet>
        <style>{`
        body { background-color: black ;   }
        `}</style>
      </Helmet>
      <Main>
        <ScoreBoard players={game.players} />

        <QuestionBoard
          categories={game.categories}
          questions={game.questions}
          socket={socket}
          gameId={gameId}
          game={game}
          played={played}
          setSelectedQuestion={setSelectedQuestion}
          updatePlayed={updatePlayed}
        />

        {allQuestions.map(({ question, answer }, idx) => {
          return (
            <AnimatedScreen
              key={idx}
              idx={idx}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              question={question}
              answer={answer}
              value={200 * ((idx % game.grid[0]) + 1)}
              players={game.players}
              // dailyDouble={game.dailyDouble.includes(idx)}
              changeScore={changeScore}
              socket={socket}
              gameId={gameId}
              buzzed={buzzed}
              clockSound={clockSound}
            />
          );
        })}

        <GameSetUp
          isVisible={game.completed !== true}
          game={game}
          themeId={themeId}
          setThemeId={setThemeId}
          setQuestions={setQuestions}
        />
        <EditButton>Edit</EditButton>

        <FeedbackButton>
          <a href="mailto:feedback@trivioom.com">Feedback</a>
        </FeedbackButton>
      </Main>
    </>
  );
};

export default MainScreenContainer;
