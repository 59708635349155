import React, { useState, Suspense } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import ReactGA from "react-ga";
import "react-datepicker/dist/react-datepicker.css";
import {
  InviteListContainer,
  InviteListContent,
  InviteInput,
  InviteColumn,
  ActionButton
} from "./styled";

const DatePicker = React.lazy(() => import("react-datepicker"));
const ENDPOINT =
  process.env.NODE_ENV === "development" ? "http://127.0.0.1:4001" : "";

const InviteList = (props) => {
  const field = { email: "", name: "" };
  const { gameId, host } = props;
  const [invitees, setInvitees] = useState(Array(5).fill(field));
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const { register, handleSubmit } = useForm();

  const inviteFriends = async (data) => {
    setLoading(true);
    let recipients = [];
    data.email.forEach((email, index) => {
      if (email !== "") recipients.push({ name: data.name[index], email });
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ emails: recipients, gameId, host, startDate }),
    };

    const inviteResult = await fetch(ENDPOINT + "/invite/send", requestOptions);

    if (inviteResult.status === 200) {
      setTimeout(() => {
        setComplete(true);
        setLoading(false);
      }, 3000);
    }

    ReactGA.event({
      category: "User",
      action: "Invited friends",
      value: recipients.length,
    });

    return;
  };

  const Invite = (props) => {
    const { name, email, index } = props;
    const [input, setInput] = useState({ name: "", email: "", index });

    const onChangeField = async (e) => {
      const { name, value } = e.target;
      let field = { ...input, [name]: value };
      await setInput(field);
    };

    return (
      <div>
        <InviteInput
          type="text"
          name={`name[${index}]`}
          placeholder="Name"
          onBlur={onChangeField}
          defaultValue={name}
          ref={register()}
        />{" "}
        <InviteInput
          type="email"
          name={`email[${index}]`}
          onBlur={onChangeField}
          placeholder="email@email.com"
          defaultValue={email}
          ref={register()}
        />
      </div>
    );
  };

  return (
    <>
      <InviteListContainer>
        <InviteColumn>
          <h3>Pick a date for your game:</h3>
          <Suspense fallback={<div>Loading...</div>}>
            <DatePicker
              showTimeSelect
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Suspense>
        </InviteColumn>
        <InviteColumn>
          <InviteListContent>
            {!complete && !loading && (
              <>
                <h3>Your friends' emails:</h3>
                <form onSubmit={handleSubmit(inviteFriends)}>
                  {invitees.map(({ name, email }, idx) => (
                    <Invite
                      name={name}
                      email={email}
                      index={idx}
                      register={register}
                    />
                  ))}
                  <ActionButton type="submit">Invite friends</ActionButton>
                </form>
              </>
            )}

            {loading && (
              <>
                <div style={{ width: "10vmin", margin: "0 auto" }}>
                  <ReactLoading
                    type="bubbles"
                    color="#fff"
                    height={"10vmin"}
                    width={"10vmin"}
                  />
                </div>
                <p>Sending invites</p>
              </>
            )}
            {complete && <p>Invites successfuly sent!</p>}
          </InviteListContent>
        </InviteColumn>
      </InviteListContainer>
    </>
  );
};

export default InviteList;
