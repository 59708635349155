import styled from "styled-components";

export const Main = styled.div`
  width: 90%;
  margin: 0 auto;
  font-family: ${(props) => props.theme.fontFamily};
`;

export const FeedbackButton = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 100px;
  text-align: center;
  background: ${(props) => props.theme.primaryColor};
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:after {
    content: "✐ ";
    padding-left: 4px;
    width: 15px;
  }

  & > a {
    text-decoration: underline;
    color: #000;
  }
`;

export const EditButton = styled(FeedbackButton)`
  background: #ffe600;
  bottom: 50px;
  &:after {
    content: "+ ";
  }
`;
