import React from "react";
import QuestionTile from "./Tile/QuestionTile";
import CategoryTile from "./Tile/CategoryTile";
import styled from "styled-components";

const QuestionBoardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const QuestionBoard = (props) => {
  const { categories } = props;

  if (!categories) return <div>No questions</div>;

  return (
    <div>
      <QuestionBoardContainer>
        {categories.map(({ title, questions }, colIndex) => {
          if (title === "" || title === null) {
            return <></>;
          }
          
          return (
            <div key={colIndex}>
              <CategoryTile title={title} game={props.game} />

              {questions.map(({ question, answer }, rowIndex) => {
                const questionId = rowIndex + colIndex * 5;
                return (
                  <QuestionTile
                    key={questionId}
                    question={question}
                    answer={answer}
                    value={200 * ((rowIndex % props.game.grid[0]) + 1)}
                    questionId={questionId}
                    setSelectedQuestion={props.setSelectedQuestion}
                    setPlayed={props.updatePlayed}
                    visible={!props.played.includes(questionId)}
                    socket={props.socket}
                    gameId={props.gameId}
                  />
                );
              })}
            </div>
          );
        })}
      </QuestionBoardContainer>
    </div>
  );
};

export default QuestionBoard;
