import React, { memo } from "react";
import { Tile } from "./styled";

const QuestionTile = memo((props) => {
  const { value, questionId, visible } = props;

  const incrementState = () => {
    props.setSelectedQuestion(questionId);
    props.setPlayed(questionId);
  };

  let card;

  if (visible) {
    card = <Tile onClick={incrementState}>${value}</Tile>;
  } else {
    card = <Tile />;
  }

  return card;
});

export default QuestionTile;
