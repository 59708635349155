import styled from "styled-components";

export const Tile = styled.div`
  background: ${({theme}) => theme.backgroundColor};
  color: ${({isCategory, theme}) => (isCategory ? theme.primaryColor : theme.secondaryColor)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: ${({isCategory}) => (isCategory ? "3vmin" : "6vmin")};
  font-weight: bold;
  height: 11vmin;
  justify-content: center;
  line-height: ${({isCategory}) => (isCategory ? "" : "11vmin")};
  margin-top: 1vmin;
  padding: 0.3125rem;
  text-align: center;
  text-shadow: ${(({theme}) => theme.textShadow)};
  width: 23vmin;
`;
