import styled from "styled-components";

export const GameSetUpContainer = styled.div`
  align-content: space-between;
  background: ${(props) => props.theme.backgroundColor};
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  left: 50%;
  min-height: 90%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const GameSetUpContent = styled.div`
  justify-content: space-between;
  color: ${({ theme }) => theme.secondaryColor};
  display: flex;
  flex-direction: column;
  min-height: 80%;
  text-align: center;
  width: 90%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ first }) => (first ? "flex-end" : "space-between")};
  margin: 1rem 3rem;
  height: 10%;
`;

export const Button = styled.div`
  background: none;
  border: none;
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  background: ${(props) => props.theme.fontFamily};
  font-size: 2rem;
  font-weight: bold;
  text-shadow: ${({ theme }) => theme.textShadow};
  text-transform: uppercase;
  text-decoration: underline;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.buttonHover || "yellow"};
  }
`;

export const QuestionListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
`;

export const QaA = styled.div`
  display: flex;
  flex-direction: column;
  margin: "5px 10px";
`;

export const Input = styled.input`
  padding: 2px;
  text-align: center;
  font-weight: ${(props) =>
    props.isCategory || props.isQuestion ? "bold" : ""};
  text-transform: ${(props) => (props.isCategory ? "uppercase" : "")};
  padding: ${(props) => (props.isCategory ? "15px 0" : "")};
`;

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Step = styled.div`
  margin: 5px 10px 25px 10px;
  opacity: ${(props) => props.opacity};
  text-shadow: ${({ theme }) => theme.textShadow};
`;

export const GameSetUpHeader = styled.div`
  min-height: 10%;
`;

// Invite list
export const InviteListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const InviteListContent = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const InviteInput = styled.input`
  font-size: 2vmin;
  margin: 3px;
  padding: 1vmin;
  width: 20vmin;
`;

export const InviteColumn = styled.div`
  margin: 0 30px;
`;

export const ActionButton = styled.button`
  background: ${({ theme }) => theme.primaryColor};
  border-radius: 10px;
  border: none;
  color: ${({ theme }) => theme.background};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 130%;
  margin: 3vmin;
  padding: 2vmin;
  width: 50vmin;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.backgroundColor};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const FinalStep = styled.div`
  margin: 0 auto;
  width: 80%;
`;