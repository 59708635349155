import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { QuestionListContainer, QaA, Input, ActionButton } from "./styled";
import { gql, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";

const UPSERT_CATEGORY = gql`
  mutation UpsertCategory(
    $constraint: categories_constraint! = categories_hash_key
    $object: categories_insert_input!
  ) {
    insert_categories_one(
      object: $object
      on_conflict: { constraint: $constraint, update_columns: [title] }
    ) {
      title
      air_date
    }
  }
`;

const GET_GAME = gql`
  query GetGame($shortCode: String!) {
    games_by_pk(shortCode: $shortCode) {
      host
      email
      categories(order_by: { order: asc }) {
        title
        hash
        order
        questions (order_by: {value: asc}) {
          answer
          question
          hash
        }
      }
    }
  }
`;

const CategoryInputs = (props) => {
  const { categoryNumber, register, control, gameId } = props;

  const [upsertCategoryMutation] = useMutation(UPSERT_CATEGORY);
  const { loading, error, data } = useQuery(GET_GAME, {
    variables: { shortCode: gameId },
  });

  const NUM_QUESTIONS = 5;
  const AIR_DATE = "custom";
  const question_array = [...Array(NUM_QUESTIONS)];
  const hash = `${gameId}${categoryNumber}`;

  const { games_by_pk: gameData } = data || {};
  console.log(gameData);
  const categoryData = gameData?.categories[categoryNumber];
  const categoryLabel = categoryData?.title || "";

  const [store, setStore] = useState({
    gameId: gameId,
    air_date: AIR_DATE,
    hash: hash,
  });

  const createCategory = (e) => {
    upsertCategoryMutation({
      variables: {
        object: store,
      },
    }).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div>
      <Controller
        control={control}
        name={`category[${categoryNumber}]`}
        defaultValue={`Category ${categoryNumber + 1}`}
        render={({ name, onChange }) => {
          return (
            <Input
              type="text"
              isCategory={true}
              name={name}
              onChange={(e) => {
                setStore({
                  ...store,
                  title: e.target.value,
                  air_date: AIR_DATE,
                  hash: hash,
                  gameId: gameId,
                  order: categoryNumber,
                });
              }}
              onBlur={(e) => createCategory(e)}
              ref={register}
              placeholder={`Category ${categoryNumber + 1}`}
              defaultValue={categoryLabel}
              key={categoryNumber}
            />
          );
        }}
      />

      {question_array.map((_, idx) => {
        const multiple = (idx + 1) * 200;
        const questionLabel = categoryData?.questions[idx]?.question || "";
        const answerLabel = categoryData?.questions[idx]?.answer || "";

        return (
          <QaA>
            <Controller
              control={control}
              name={`question[${categoryNumber}][${idx}]`}
              defaultValue={`Question ${idx + 1}: $${(idx + 1) * 200}`}
              render={({ name, onChange }) => {
                return (
                  <Input
                    type="text"
                    isQuestion={true}
                    name={name}
                    onChange={(e) => {
                      setStore({
                        ...store,
                        title: categoryLabel,
                        questions: {
                          data: [
                            {
                              value: multiple,
                              question: e.target.value,
                              hash: `${hash}${multiple}`,
                              gameId: gameId,
                            },
                          ],
                          on_conflict: {
                            constraint: "questions_hash_key",
                            update_columns: ["question"],
                          },
                        },
                      });
                    }}
                    onBlur={(e) => createCategory(e)}
                    ref={register}
                    placeholder={`Question ${idx + 1}: $${multiple}`}
                    defaultValue={questionLabel}
                    key={"q" + categoryNumber * idx}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={`answer[${categoryNumber}][${idx}]`}
              defaultValue={`Answer ${idx + 1}`}
              render={({ name, onChange }) => {
                return (
                  <Input
                    type="text"
                    name={name}
                    onChange={(e) => {
                      setStore({
                        ...store,
                        title: categoryLabel,
                        questions: {
                          data: [
                            {
                              value: multiple,
                              answer: e.target.value,
                              hash: `${hash}${multiple}`,
                              gameId: gameId,
                            },
                          ],
                          on_conflict: {
                            constraint: "questions_hash_key",
                            update_columns: ["answer"],
                          },
                        },
                      });
                    }}
                    onBlur={(e) => createCategory(e)}
                    ref={register}
                    placeholder={`Answer ${idx + 1}`}
                    defaultValue={answerLabel}
                    key={"a" + categoryNumber * idx}
                  />
                );
              }}
            />
          </QaA>
        );
      })}
    </div>
  );
};

export default CategoryInputs;
