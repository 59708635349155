import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import socketIOClient from "socket.io-client";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";

const ENDPOINT =
  process.env.NODE_ENV === "development" ? "http://127.0.0.1:4001" : "";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1em;
`;

const animate = keyframes`
0% {box-shadow: 0 20px #999}
100% {transform:translateY(15px); box-shadow: 0 5px #666}
`;

const Buzzer = styled.div`
  padding: 10px;
  margin: 20px;
  background-color: ${({ buzzed }) => (!buzzed ? "#E94F37" : "#E94F37")};
  color: ${({ buzzed }) => (buzzed ? "#393E41" : "#a73827")};
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 120px;
  height: 120px;
  cursor: pointer;
  display: flex;
  box-shadow: ${({ buzzed }) => (buzzed ? "0 5px #666" : "0 20px #999")};
  transform: ${({ buzzed }) => (buzzed ? "translateY(15px)" : "")};

  &:active {
    animation-name: ${animate};
    animation-duration: 0.2s;
    animation-iteration-count: 1;
  }
`;

const BuzzerContainer = (props) => {
  let history = useHistory();
  if (props.location.state === undefined) {
    history.push({ pathname: "/", state: { gameId: props.match.params.id } });
  }

  const [buzzed, setBuzzed] = useState(false);
  const [question, setQuestion] = useState("");
  const socket = socketIOClient(ENDPOINT);
  const player = props?.location?.state?.name || "";
  const gameId = props.match.params.id || "1111";

  useEffect(() => {
    socket.emit("room", gameId);
    socket.emit("addPlayer", { gameId, name: player });

    socket.on("game", (data) => {
      setQuestion(data.question);
    });

    socket.on("reset", (data) => {
      setBuzzed(false);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleBuzz = (e) => {
    if (buzzed) return;

    socket.emit("buzzer", {
      user: player,
      game: gameId,
      value: "buzzed",
      time: Date.now(),
    });
    setBuzzed(true);
  };

  return (
    <>
      <Helmet>
        <style>
          {"body {overscroll-behavior-y: contain; touch-action: none }"}
        </style>
      </Helmet>
      <Container>
        <Item>
          <h1>{player}</h1>
        </Item>
        <Item>{question}</Item>
        <Item>
          <Buzzer onClick={handleBuzz} buzzed={buzzed}>
            {buzzed ? "Buzzed!" : "Press"}
          </Buzzer>
        </Item>
      </Container>
    </>
  );
};

export default BuzzerContainer;
