import styled from "styled-components";

export const AnimatedScreenContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.backgroundColor};
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-flow: row wrap;
  height: 90%;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const AnimatedScreenContent = styled.div`
  align-items: center;
  color: white;
  display: flex;
  font-size: 3em;
  height: 80%;
  justify-content: center;
  text-align: center;
  text-shadow: 2px 2px black;
  width: 90%;

  & .dailyDouble {
    color: ${(props) => props.theme.primaryColor};
  }

  & .answer {
    width: 100%;
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const ProgressBarContainer = styled.div`
  align-items: center;
  display: flex;
  height: 5%;
  justify-content: center;
  width: 90%;
`;

export const ProgressBar = styled.div`
  background: ${(props) => (props.background ? "white" : "red")};
  height: 100%;
  width: ${(props) => props.width}%;
`;

export const Players = styled.div`
  align-content: center;
  display: flex;
  flex-direction: row;
  height: 10%;
  justify-content: space-evenly;
  width: 90%;
`;

export const Button = styled.div`
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  text-decoration: underline;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.buttonHover || "yellow"};
  }
`;

export const Name = styled.span`
  color: ${({ buzzed, theme }) =>
    buzzed ? theme.primaryColor : theme.secondaryColor};
  text-decoration: ${({ buzzed }) => (buzzed ? "underlined" : "")};
  font-size: 100%;
`;

export const AnswerButton = styled.button`
  border: none;
  font-weight: bold;
  height: 30px;
  margin: 0 5px;
  padding: 0;
  text-align: center;
  width: 30px;

  &:hover,
  &:active {
    background: ${({ add }) => (add ? "#98FB98" : "#FA8072")};
    cursor: pointer;
  }

  &:focus {
    background: ${({ add }) => (add ? "#008000" : "#DC143C")};
  }
`;
