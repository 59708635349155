import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  color: black
  heigth: 100%;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

export const MenuContainer = styled.div`
  color: black;
  text-align: center;
  padding: 1rem;
  color: #000;
  & > ul {
    float: right;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    display:none
  }
`;

export const MenuItem = styled.li`
  float: left;
  list-style-type: none;
  margin: 0 1rem;
  text-align: right;
  background: ${(props) => (props.button ? "black" : "")};
  border-radius: ${(props) => (props.button ? "30px" : "")};
  padding: ${(props) => (props.button ? "10px 40px" : "10px 0")};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  & > a {
    color: ${(props) => (props.button ? "rgba(137,207,240,1)" : "black")};
    text-decoration: none;
  }
`;

export const JoinOrCreateBox = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 5% auto;
  flex-direction: column;

  & > h1,
  h2 {
    clear: both;
    text-align: center;
    padding: 5px;
    margin: 0;
  }

  & > h2 {
    font-weight: normal;
  }

  & > div {
    display: flex;
    justify-content: center;
  }
`;

export const Box = styled.div`
  text-align: center;
  padding: 3rem 3rem;
  margin: 1rem;
  min-width: 300px;
  display: ${({ display }) => (display ? "flex" : "none")};
  flex-direction: column;
`;

export const Input = styled.input`
  padding: 1rem;
  margin: 2%;
  font-size: 1rem;
  text-align: center;
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
,`;

export const Button = styled.button`
  background: ${({ outline }) => (!outline ? "#24305e" : "transparent")};
  border: ${({ outline }) => (outline ? "1px solid black" : "transparent")};
  color: ${({ outline }) => (outline ? "black" : "white")};
  padding: 1rem;
  margin: 2%;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
  border-radius: 20px;
  &:hover {
    background: #161d39;
    cursor: pointer;
    color: #fff;
  }
`;

export const Small = styled.small`
  font-size: 0.9%;
  font-weight: bold;
`;
