import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

const FooterContainer = styled.div`
  text-align: center;
  fontsize: 90%;
  paddingtop: 5%;
`;

const Footer = (props) => {
  return (
    <FooterContainer>
      &copy; Trivioom 2020 | <Link to="/terms">Terms and Conditions</Link>
    </FooterContainer>
  );
};

export default Footer;
