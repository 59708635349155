import React, { useState, useEffect } from "react";
import InviteList from "./InviteList";
import Steps from "./Steps";
import ThemeSelector from "./ThemeSelector";
import QuestionList from "./QuestionList";
import ReactGA from "react-ga";

import {
  Button,
  ButtonsContainer,
  GameSetUpContainer,
  GameSetUpContent,
  ActionButton,
  FinalStep
} from "./styled";

const GameSetUp = (props) => {
  const { themeId, setThemeId } = props;
  const { game } = props;
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const [step, setStep] = useState(0);

  localStorage.removeItem(game.id)

  useEffect(() => {
    if (step === 0) {
      ReactGA.event({
        category: "User",
        action: "Started the flow",
      });
    }
    if (step === 2) {
      {
        ReactGA.event({
          category: "User",
          action: "Finished set-up",
        });
      }
    }
  }, [step]);

  const updateStep = (increment) => {
    setStep(step + increment);
  };

  return (
    <GameSetUpContainer isVisible={isVisible}>
      <GameSetUpContent>
          <Steps currentStep={step} />

          {step === 0 && (
            <ThemeSelector setThemeId={setThemeId} selected={themeId} />
          )}
          {step === 1 && <QuestionList setQuestions={props.setQuestions} gameId={game.id} />}
          {step === 2 && (
            <InviteList isVisible={true} gameId={game.id} host={game.host} />
          )}
          {step === 3 && (
            <FinalStep>
              <p>You are all set! Play the game now or come back later!</p>{" "}
              <ActionButton onClick={() => setIsVisible(false)}>Close</ActionButton>
            </FinalStep>
          )}

        <ButtonsContainer first={step === 0}>
          {step > 0 && <Button onClick={() => updateStep(-1)}>Previous</Button>}
          {step < 3 && <Button onClick={() => updateStep(1)}>Next</Button>}
        </ButtonsContainer>
      </GameSetUpContent>
    </GameSetUpContainer>
  );
};

export default GameSetUp;
