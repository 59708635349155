import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { isMobile } from "react-device-detect";
import {
  Box,
  Button,
  Container,
  Input,
  JoinOrCreateBox,
  Small,
} from "./styled";
import Menu from "./Menu";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";
import bgImg from "./bg.png";

// Database
import { gql } from "apollo-boost";
import { withApollo } from "@apollo/react-hoc";
import { useMutation } from "@apollo/react-hooks";
import { setApiKey } from "@sendgrid/mail";

const ENDPOINT =
  process.env.NODE_ENV === "development" ? "http://127.0.0.1:4001" : "";

const CREATE_GAME = gql`
  mutation($host: String!, $shortCode: String!, $ip: String!, $email: String!) {
    insert_games_one(object: { host: $host, shortCode: $shortCode, ip: $ip, email: $email }) {
      id
    }
  }
`;

const LandingScreen = (props) => {
  let history = useHistory();
  const [gameSetUp, setGameSetUp] = useState({});
  const [clientIp, setClientIp] = useState();
  const [createGameMutation] = useMutation(CREATE_GAME);
  const existingGame = props?.location?.state?.gameId || undefined;

  useEffect(() => {
    if (!props?.location?.state?.gameId) {
      const game = uuidv4().split("-")[0].toUpperCase();
      setGameSetUp({ game });
    }

    fetch("https://api.ipify.org?format=json")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        setClientIp(res.ip);
      })
      .catch((err) => console.log(err));
  }, []);

  const joinGame = (e) => {
    const { game, userName } = gameSetUp;
    const redirectGameId = game !== undefined ? game : existingGame;
    history.push({
      pathname: `/join/${redirectGameId}`,
      state: { name: userName },
    });
  };

  const createGame = async (e) => {
    const { game, host, hostEmail } = gameSetUp;

    createGameMutation({
      variables: { host, shortCode: game, ip: clientIp, email: hostEmail },
    }).catch(function (error) {
      console.log(error);
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        emails: [{ name: host, email: hostEmail }],
        game,
        host,
        initial: true,
      }),
    };

    const inviteResult = await fetch(ENDPOINT + "/invite/send", requestOptions);

    history.push({
      pathname: `/host/${game}`,
      state: { host, hostEmail },
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setGameSetUp({ ...gameSetUp, [name]: value });
  };

  return (
    <>
      <Container>
        <img src={bgImg} id="bg" alt="" />
        <Menu />
        <JoinOrCreateBox>
          <h1>Play online trivia remotely with your friends!</h1>
          <h2>
            Use Zoom, Google Hangouts, or other video calling software play
            trivia with a built-in buzzer
          </h2>
          <div>
            <Box display={true}>
              <h2>Join an existing game</h2>
              <Input
                name="userName"
                id="userName"
                placeholder="Your name"
                onChange={(e) => onChange(e)}
              />
              <Input
                name="game"
                id="game"
                placeholder="Game Id"
                maxLength="8"
                value={existingGame}
                onChange={(e) => onChange(e)}
                disabled={existingGame !== undefined}
              />

              <Button onClick={joinGame} outline={true}>
                Join a game
              </Button>
            </Box>

            {!isMobile && (
              <Box display={existingGame === undefined}>
                <h2>Host a new game!</h2>
                <Input
                  name="host"
                  id="hostGame"
                  placeholder="Your name"
                  onChange={(e) => onChange(e)}
                />

                <Input
                  name="hostEmail"
                  placeholder="Your email (optional)"
                  onChange={(e) => onChange(e)}
                />

                <Button onClick={createGame}>Create a new game</Button>
              </Box>
            )}
          </div>
        </JoinOrCreateBox>
        <FAQ />
        <div>
          <ul>
            <li>College courses</li>
            <li>Work meetings</li>
            <li>Virtual team building</li>
            <li>Hanging out with friends</li>
            <li>High school classes</li>
            <li>Remote work off-sites</li>
          </ul>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withApollo(LandingScreen);
