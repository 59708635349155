const mobileWidth = "810px";

export const theme = [
  {
    backgroundColor: "midnightblue",
    fontFamily: "Impact",
    label: "Classic",
    mobile: mobileWidth,
    primaryColor: "orange",
    secondaryColor: "#fff",
    textShadow: "2px 2px black",
  },
  {
    backgroundColor: "#FDD20EFF",
    fontFamily: "Londrina Solid",
    label: "Fun",
    mobile: mobileWidth,
    primaryColor: "#F93822FF",
    secondaryColor: "#fff",
    textShadow: "1px 1px black",
  },
  {
    backgroundColor: "#bb0000",
    fontFamily: "Graduate",
    label: "College",
    mobile: mobileWidth,
    primaryColor: "#fff",
    secondaryColor: "#fff",
    textShadow: "2px 2px black",
  },
  {
    backgroundColor: "#eb6123",
    fontFamily: "Creepster",
    label: "Halloween",
    mobile: mobileWidth,
    primaryColor: "#fff",
    secondaryColor: "#fff",
    textShadow: "2px 2px black",
  },
  {
    backgroundColor: "#000",
    fontFamily: "Patrick Hand",
    label: "School",
    mobile: mobileWidth,
    primaryColor: "#d6d963",
    secondaryColor: "#fff",
    textShadow: "2px 2px black",
  },
  {
    backgroundColor: "#000",
    fontFamily: "VT323",
    label: "The 80s",
    mobile: mobileWidth,
    primaryColor: "#00b140",
    secondaryColor: "#00b140",
    textShadow: "2px 2px black",
  },
  {
    // backgroundColor: "#fff",
    // buttonHover: "#000",
    // fontFamily: "Catamaran",
    // label: "Brandeis",
    // mobile: mobileWidth,
    // primaryColor: "#003478",
    // secondaryColor: "#003478",
    // textShadow: "",
  },
];
