import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { gql, useQuery } from "@apollo/client";

import CategoryInputs from "./CategoryInputs";
import { QuestionListContainer, ActionButton } from "./styled";

const GET_CATEGORIES = gql`
  query MyQuery($offset: Int!) {
    categories(limit: 6, offset: $offset) {
      title
      questions {
        answer
        question
        value
      }
    }
  }
`;

const randomOffset = Math.min(Math.round(Math.random() * 300, 0), 300);

const QuestionList = ({ gameId, setQuestions }) => {
  const { register, handleSubmit, control } = useForm();
  const { loading, data } = useQuery(GET_CATEGORIES, {
    variables: { offset: randomOffset },
  });
  const [manual, setManual] = useState();

  useEffect(() => {
    if (manual === false && !loading) {
      setQuestions(data.categories);
    }
  }, [manual]);

  const saveQuestions = (data) => {
    let ds = [];

    data.category.map((category, catIdx) => {
      let questions = [];

      data.question.map((item, idx) => {
        questions.push({
          question: data.question[catIdx][idx],
          answer: data.answer[catIdx][idx],
          order: idx,
        });
        return null;
      });

      ds.push({
        order: catIdx,
        title: category,
        questions: questions.slice(0, -1),
      });
      return null;
    });

    setQuestions(ds);
  };

  const saveQuestions2 = (data) => {
    console.log(data.pre);
  };

  const SelectionButton = ({ text, onClick, isSelected }) => {
    return (
      <ActionButton active={false} onClick={onClick}>
        {isSelected ? "✓ " : ""}
        {text}
      </ActionButton>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SelectionButton
          text={"Create your own board"}
          isSelected={manual === true}
          onClick={() => setManual(true)}
        />
        <SelectionButton
          text={"Quick start with existing categories"}
          isSelected={manual === false}
          onClick={() => setManual(false)}
        />
      </div>
      <div style={{ display: manual ? "block" : "none" }}>
        {manual}
        <form onSubmit={handleSubmit(saveQuestions)}>
          <QuestionListContainer>
            {[...Array(6)].map((_, idx) => (
              <CategoryInputs
                categoryNumber={idx}
                register={register()}
                control={control}
                gameId={gameId}
              />
            ))}
          </QuestionListContainer>
          <button type="submit">Save questions</button>
        </form>
      </div>

      <div style={{ display: !manual ? "block" : "none" }}></div>
    </>
  );
};

export default QuestionList;
