import React from "react";
import { StepsContainer, Step, GameSetUpHeader } from "./styled";

const Steps = (props) => {
  const { currentStep } = props;

  const steps = [
    "Select a theme",
    "Set questions",
    "Invite friends",
    "Complete",
  ];

  return (
    <GameSetUpHeader>
      <h1>Game Set Up</h1>
      <StepsContainer>
        {steps.map((label, index) => {
          return (
            <Step opacity={currentStep === index ? 1 : 0.4} key={index}>
              {index + 1}. {label}
            </Step>
          );
        })}
      </StepsContainer>
    </GameSetUpHeader>
  );
};

export default Steps;
