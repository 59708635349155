import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html {
    overflow-x: hidden;  
  }

  body {
    background: #0D0C1D;
    color: #000;
    font-family: 'Poppins', sans-serif;
    background:rgba(137,207,240,1);
    justify-content: center;
    text-rendering: optimizeLegibility;
  }

  #bg {
    position: fixed; 
    top: -200px; 
    left: -300px; 
    width:150%;
    height:100%;
    z-index: -99;
    
    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 810px;
      }
  }
  `;
