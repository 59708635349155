import React from "react";
import styled from "styled-components";

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  justify-content: center;
  font-size: 90%;

  & > div {
    width: 100%;
  }
`;

const FAQ = (props) => {
  const FAQItems = [
    {
      heading: "How do you play with Zoom or Google Hangouts?",
      body:
        "Playing trivia with Trivioom using Zoom or Google Hangouts is easy",
    },
    {
      heading: "Who is Trivioom trivia game for?",
      body:
        "Playing trivia with Trivioom using Zoom or Google Hangouts is easy",
    },
    {
      heading: "How many players can play trivia with Trivioom?",
      body:
        "You can have up to ten friends and colleagues playing simulteneously.",
    },
    {
      heading: "How do I buzz when I know the correct answer?",
      body:
        "When you know the correct answer, just press the buzzer icon in the app. The host will see who buzzed in first and give you chance to answer.",
    },
  ];

  return (
    <FAQContainer id="howItWorks">
      {FAQItems.map(({ heading, body }, idx) => {
        return (
          <div key={idx}>
            <h2>{heading}</h2>{" "}
            <p dangerouslySetInnerHTML={{ __html: body }}></p>
          </div>
        );
      })}
    </FAQContainer>
  );
};

export default FAQ;
