import React from "react";
import { MenuContainer, MenuItem } from "./styled";
import { HashLink as Link } from 'react-router-hash-link';


const Menu = (props) => {
  return (
    <MenuContainer>
      <ul>
        <MenuItem>
          <Link smooth to="/">Home</Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="/#howItWorks">How it works</Link>
        </MenuItem>
        <MenuItem>
          <Link smooth to="/#userName">Join</Link>
        </MenuItem>
        <MenuItem button={true}>
          <Link smooth to="/#hostGame">Create a game</Link>
        </MenuItem>
      </ul>
    </MenuContainer>
  );
};

export default Menu;
