import React from "react";
import {theme} from "../theme";

const ThemeSelector = (props) => {
  const styles = {
    container: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
    },
    themeBox: {
      width: "150px",
      height: "150px",
      margin: "5px",
      border: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      {theme.map((theme, idx) => {
        return (
          <div
            style={{
              ...styles.themeBox,
              fontFamily: theme.fontFamily,
              background: theme.backgroundColor,
              color: theme.primaryColor,
              textShadow: theme.textShadow,
              fontSize: "2rem",
              border:
                idx == props.selected
                  ? "4px dashed black"
                  : `4px solid ${theme.backgroundColor}`,
            }}
            key={idx}
            onClick={() => props.setThemeId(idx)}
          >
            {theme.label}
          </div>
        );
      })}
    </div>
  );
};

export default ThemeSelector;
