import React from "react";
import styled from "styled-components";

const ScoreBoardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

const PlayerContainer = styled.div`
  text-align: center;
  font-size: 2rem;
  padding: 30px;

  & .name {
    font-size: 120%;
    text-decoration: underline;
    color: #fff;
  }
`;

const Score = styled.span`
  color: ${({ negative }) => (negative ? "red" : "white")};
`;

const ScoreBoard = (props) => {
  const { players } = props;
  if (!players) return <div />;

  return (
    <ScoreBoardContainer>
      {players.map(({ name, score }) => {
        const scoreColor = score < 0 ? "red" : "white";
        score = score < 0 ? "-$" + -score : "$" + score;
        return (
          <PlayerContainer>
            <span className="name">{name}</span>
            <br />
            <Score negative={score < 0}>{score}</Score>
          </PlayerContainer>
        );
      })}
    </ScoreBoardContainer>
  );
};

export default ScoreBoard;
