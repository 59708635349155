import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { useKeyPress } from "../../hooks/useKeyPress";
import useSound from "use-sound";
import wrongSound from "../../sounds/wrong.mp3";
import correctSound from "../../sounds/correct.mp3";
import Linkify from "react-linkify";
import {
  AnimatedScreenContainer,
  AnimatedScreenContent,
  AnswerButton,
  Button,
  Name,
  Players,
  ProgressBar,
  ProgressBarContainer,
} from "./styled";

const AnimatedScreen = (props) => {
  const {
    idx,
    selectedQuestion,
    question,
    answer,
    players,
    value,
    changeScore,
    socket,
    gameId,
    buzzed,
  } = props;
  const [wager, setWager] = useState(value);
  const [dailyDouble, setDailyDouble] = useState(props.dailyDouble);
  const [state, setState] = useState(0);
  const [counter, setCounter] = useState(0);
  const advance = useKeyPress("n");
  const isVisible = selectedQuestion === idx;

  const [playWrong] = useSound(wrongSound, { volume: 0.25 });
  const [playCorrect] = useSound(correctSound, { volume: 0.25 });

  useEffect(() => {
    if (isVisible) {
      socket.emit("game", { gameId, question });
    }
  }, [isVisible]);

  const createCounter = (time, reset) => {
    if (reset) setCounter(0);
    setInterval(() => {
      setCounter((counter) => counter + time);
    }, 1000);
  };

  const reset = () => {
    socket.emit("reset", { gameId });
    createCounter(25, true);
  };

  const updateState = () => {
    setState(state + 1);
    if (counter === 0) setCounter(0.1);

    if (state === 0) {
      createCounter(20);
    }
    if (state === 2) {
      socket.emit("reset", { gameId });
      props.setSelectedQuestion(null);
    }
  };

  const DailyDouble = (props) => {
    const [newWager, setNewWager] = useState(props.wager);
    const handleChange = (e) => {
      setNewWager(e.target.value);
    };

    return (
      <div>
        <h1 className="dailyDouble">Double Reward!</h1>
        <AnimatedScreenContent style={{ fontSize: "1.5rem" }}>
          {props.players.map((item) => {
            return (
              <div style={{ margin: "15px" }}>
                <h1>{item.name}</h1>
                <h2>${item.score}</h2>
              </div>
            );
          })}
        </AnimatedScreenContent>
        <input type="text" placeholder="Your wager" onChange={handleChange} />
        <input
          type="submit"
          value="Set wager"
          onClick={() => {
            setWager(newWager);
            props.setDailyDouble(false);
          }}
        />
        {/* <div
          style={{ fontSize: "1rem" }}
          onClick={() => props.setDailyDouble(false)}
        >
          Close
        </div> */}
      </div>
    );
  };

  // TO-DO Fix this
  const customFontSize = answer.length > 300 ? { fontSize: "0.6em" } : {};

  const ScoreList = (props) => {
    const { changeScore, idx, name } = props;
    const disabled = buzzed !== name;

    return (
      <div>
        <AnswerButton
          onClick={() => {
            changeScore(idx, wager);
            playCorrect();
          }}
          add
          disabled={disabled}
        >
          +
        </AnswerButton>
        <Name buzzed={buzzed}>{name}</Name>
        <AnswerButton
          onClick={() => {
            changeScore(idx, -wager);
            playWrong();
          }}
          disabled={disabled}
        >
          -
        </AnswerButton>
      </div>
    );
  };

  return (
    <Animated
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={isVisible}
    >
      <AnimatedScreenContainer isVisible={isVisible}>
        <AnimatedScreenContent>
          <div>
            {dailyDouble && (
              <DailyDouble
                players={players}
                wager={wager}
                setWager={setWager}
                setDailyDouble={setDailyDouble}
              />
            )}
            {!dailyDouble && (
              <div dangerouslySetInnerHTML={{ __html: question }} />
            )}
            {state > 1 && (
              <div
                className="answer"
                style={customFontSize}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            )}
          </div>
        </AnimatedScreenContent>

        <ProgressBarContainer>
          {!buzzed && (
            <ProgressBar width={counter} background={counter < 100} />
          )}
        </ProgressBarContainer>

        <Players>
          {players.map(({ name }, idx) => (
            <ScoreList name={name} idx={idx} changeScore={changeScore} />
          ))}
          <Button onClick={reset}>Reset</Button>
          <Button onClick={updateState}>Next</Button>
        </Players>
      </AnimatedScreenContainer>
    </Animated>
  );
};

export default AnimatedScreen;
