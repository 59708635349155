import React, { useEffect, useState } from "react";
import MainScreenContainer from "./MainScreen/MainScreenContainer";
import BuzzerContainer from "./BuzzerContainer";
import TermsAndConditions from "./TermsAndConditions";
import LandingScreen from "./LandingScreen/LandingScreen";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { GlobalStyles } from "./global";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

// Apollo client
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";

// Google analytics
ReactGA.initialize("UA-86507656-2");
ReactGA.pageview(window.location.pathname + window.location.search);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [themeId, setThemeId] = useState(0);

  const httpLink = new HttpLink({
    uri: "https://credible-llama-98.hasura.app/v1/graphql",
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });

  return (
    <ThemeProvider theme={theme[themeId]}>
      <GlobalStyles />
      <ApolloProvider client={client}>
        <Helmet>
          <title>
            {`Online trivia | Free online trivia for remote teams and friends via
            Zoom and Google Hangouts`}
          </title>
          <meta
            name="description"
            content="Play custom and ready trivia games with a built-in buzzer. Follow Zoom and Google Hangouts instructions to play trivia."
          />
          <meta property="og:title" content="Online trivia" />
          <meta property="og:url" content="https://www.trivioom.com/" />
          <meta
            property="og:description"
            content="Play custom and ready trivia games with a built-in buzzer. Follow Zoom and Google Hangouts instructions to play trivia."
          />
          <meta
            property="og:image"
            content="https://www.trivioom.com/favicon.png"
          />
        </Helmet>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <LandingScreen {...props} />}
            />
            <Route
              exact
              path="/terms"
              render={(props) => <TermsAndConditions {...props} />}
            />
            <Route
              path="/join/:id"
              render={(props) => <BuzzerContainer {...props} />}
            />
            <Route
              path="/host/:id"
              render={(props) => (
                <MainScreenContainer
                  {...props}
                  setThemeId={setThemeId}
                  host={true}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
